import { MusicSlider } from "../../../components";
import { RoutePattern } from "../../../routes/RoutePattern";
import { musicData } from "./MusicData";
// import styled from 'styled-components';

// const MusicImage = styled.a`
//   transition: all 0.3s ease;
//   overflow: hidden;

//   :hover img {
//     transform: scale(1.2);
//     transition: all 0.3s ease;
//   }
// `;

export default function Music() {
  return (
    <div
      id={RoutePattern.MUSIC}
      className="min-h-screen flex justify-center items-center bg-black"
    >
      <div>
        <MusicSlider data={musicData} />
      </div>
    </div>
  );
}
