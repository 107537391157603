import styled from "styled-components";
import { ArrowTypes } from "./types";

export const Arrow = styled.button<ArrowTypes>`
  display: block;
  position: absolute;
  top: 95%;
  right: ${(props) => props.right && "5%"};
  left: ${(props) => props.left && "5%"};
  z-index: 2;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 100;
  outline: none;
  color: white;
  :focus {
    outline: none;
  }

  @media (min-width: 768px) {
    top: 93%;
    right: ${(props) => props.right && "30%"};
    left: ${(props) => props.left && "30%"};
  }

  @media (min-width: 1024px) {
    top: 50%;
    right: ${(props) => props.right && "46%"};
    left: ${(props) => props.left && "46%"};
  }

  @media (min-width: 1268px) {
    top: 50%;
    right: ${(props) => props.right && props.right};
    left: ${(props) => props.left && props.left};
  }
`;
