import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { YoutubeItemTypes } from "./types";
import styled from "styled-components";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Arrow } from "./styled.components";
import { RoutePattern } from "../../../routes/RoutePattern";

// Old: GKjY6-lhY2A
const data = [
  {
    videoId: "FRHZXScuRag",
    title: "Victory - Just Like In Heaven",
    stream: "https://www.youtube.com/watch?v=FRHZXScuRag",
  },
  {
    videoId: "aJD0erXjG-E",
    title: "Victory Boyd - El Shaddai",
    stream: "https://www.youtube.com/watch?v=aJD0erXjG-E",
  },
  {
    videoId: "1jfXb8AIIsA",
    title: "Victory Boyd - El Shaddai (Official Lyric Video)",
    stream: "https://www.youtube.com/watch?v=1jfXb8AIIsA",
  },
  {
    videoId: "jWSn5nd44qg",
    title: "Victory Boyd - Just Like In Heaven (Official Visualizer)",
    stream: "https://www.youtube.com/watch?v=jWSn5nd44qg",
  },
];

const Iframe = styled.iframe`
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 1rem !important;
`;

const VideoItem = styled.div`
//   max-width: 350px;
  width: 100%;
  height: 275px;
  margin: auto;

  @media (min-width: 740px) {
    height: 100vh;
    max-width: 700px;
    padding: 10px
  }

  @media (min-width: 1024px) {
    height: 410px;
    max-width: 700px;
    padding: 0;
  }
`;

export default function Youtube() {
  const [youtubeItems, setYoutubeItems] = useState<YoutubeItemTypes[]>(data);

  useEffect(() => {
    //   get the youtube playlist
    handleRequest();
  }, []);

  async function handleRequest() {
    const playlist_id = "PLyxA9OT_6hdpEJghlWkwCrlsuz9huls3x";
    const youtube_api = process.env.REACT_APP_YOUTUBE_API;
    const video_list = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlist_id}&fields=items(snippet(resourceId%2FvideoId%2Cthumbnails%2Fmedium%2Furl%2Ctitle))&key=${youtube_api}`;

    const { data } = await axios.get(video_list);

    const filteredData: YoutubeItemTypes[] = [];

    data.items.map((item: any) => {
      filteredData.push({
        videoId: item.snippet.resourceId.videoId,
        title: item.snippet.title,
      });
    });

    setYoutubeItems(filteredData);
  }

  const renderArrowPrev = (
    onClickHandler: any,
    hasPrev: boolean,
    label: string
  ) => (
    <Arrow
      type="button"
      onClick={onClickHandler}
      left="25%"
      title={label}
      className="from-lef"
    >
      <FontAwesomeIcon icon={faAngleLeft as any} />
    </Arrow>
  );

  const renderArrowNext = (
    onClickHandler: any,
    hasNext: boolean,
    label: string
  ) => (
    <Arrow
      type="button"
      onClick={onClickHandler}
      right="25%"
      title={label}
      className="from-righ"
    >
      <FontAwesomeIcon icon={faAngleRight as any} />
    </Arrow>
  );

  const items = data.map((item, idx) => (
    <VideoItem
      key={idx}
      className="flex justify-center items-center flex-col text-white px-5"
    >
      <Iframe
        id={item.videoId}
        className="w-full h-full"
        src={`https://www.youtube-nocookie.com/embed/${item.videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <span className="text-">
        <h2 className="font-bold font-gotham-black italic text-sm md:whitespace-nowrap md:text-xl lg:text-2xl">
          {item.title}
        </h2>
        {/* <a
          // href={`https://www.youtube.com/${item.videoId}`}
          href={item.stream}
          className="uppercase font-calibre-medium underline text-sm whitespace-nowrap md:text-xl"
        >
          stream / download now
        </a> */}
      </span>
    </VideoItem>
  ));

  return (
    <div
      id={RoutePattern.VIDEOS}
      className="min-h-screen w-full flex justify-center items-center lg:py-24 bg-black text-white"
    >
      <Carousel
        infiniteLoop
        swipeable={false}
        emulateTouch={false}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
      >
        {items}
      </Carousel>
    </div>
  );
}
