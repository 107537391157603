import {
  Box,
  // Button,
  // Heading,
  // Image,
} from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { RoutePattern } from "../../../routes/RoutePattern";
import HERO_DESKTOP from "../../../assets/images/Victory desktop.png";
import HERO_MOBILE from "../../../assets/images/Victory mobile.png";
import SITE_LOGO from "../../../assets/images/victory white.png";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";

const Showcase = () => {
  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden"
    >
      <div
        className="absolute top-2 md:top-10 left-2 md:left-10"
      >
        <img 
          className="h-24 md:h-32"
          src={SITE_LOGO}
          alt="logo"
        />
      </div>
      <img
        className="hidden sm:block object-cover w-full h-auto"
        src={HERO_DESKTOP}
        alt="bg"
      />
      <img
        className="block sm:hidden h-screen w-full object-cover"
        src={HERO_MOBILE}
        alt="bg"
      />
      <Box
        className="absolute bottom-20 md:bottom-5 w-full text-center"
      >
        <a href="https://victoryboyd.komi.io/music/GloryHourLive" target="_blank">
          <button
            className="text-2xl md:text-3xl border px-11 md:px-20 py-1 pt-2 md:pt-1 m-2 md:m-5 bg-black text-white hover:bg-white hover:text-black"
          >
            LISTEN
          </button>
        </a>
        <a href="https://victory.lnk.to/OneThingLiveVideo" target="_blank">
          <button
            className="text-2xl md:text-3xl border px-11 md:px-20 py-1 pt-2 md:pt-1 m-2 md:m-5 bg-black text-white hover:bg-white hover:text-black"
          >
            WATCH
          </button>
        </a>
        <a href="https://victory.rosecityworks.com/" target="_blank">
          <button
            className="text-2xl md:text-3xl border px-[49px] md:px-20 py-1 pt-2 md:pt-1 m-2 md:m-5 bg-black text-white hover:bg-white hover:text-black"
          >
            SHOP
          </button>
        </a>
        <button
          className="tour text-2xl md:text-3xl border px-[40px] md:px-[75px] py-1 pt-2 md:pt-1 m-2 md:m-5 bg-black text-white hover:bg-white hover:text-black"
        >
          SEE LIVE
        </button>
      </Box>
    </Box>
  );
};

export default Showcase;
