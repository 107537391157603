import { MotionProps } from 'framer-motion';
import { TransitionPowerOne, TransitionPowerThree } from './Transitions';

export const FadeInThenUp: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: { ...TransitionPowerOne },
};

export const FadeIn: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  transition: { ...TransitionPowerOne },
};

export const fadeInFadeOut: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    delay: 0.4,
    duration: 0.8,
    ...TransitionPowerThree,
  },
};
