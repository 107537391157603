import {
    Box,
    // Button,
    // Heading,
    // Image,
  } from "../../../components/StyledComponent";
  import React, { useEffect, useRef, useState } from "react";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import HERO_DESKTOP from "../../../assets/images/Victory desktop.png";
  import HERO_MOBILE from "../../../assets/images/Victory mobile.png";
  import styled from "styled-components";
  
  const Showcase = () => {
    return (
      <Box
        id={RoutePattern.TOUR}
        className="relative h-screen w-full mt-10 md:mt-20"
      >
        <div
            className="text-5xl text-center md:pt-10"
        >
            TOUR
        </div>
        <Box
          className="w-full md:px-20"
        >
            <a className="bit-widget-initializer" data-artist-name="Victory Boyd" data-display-local-dates="false" data-display-past-dates="false" data-auto-style="false" data-text-color="#FFFFFF" data-link-color="#c8a25d" data-background-color="rgba(0,0,0,0)" data-display-limit="15" data-display-start-time="false" data-link-text-color="#000000" data-display-lineup="false" data-display-play-my-city="true" data-separator-color="rgba(124,124,124,0.25)"></a>
        </Box>
      </Box>
    );
  };
  
  export default Showcase;
  