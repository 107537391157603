import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import Tour from "./Tour/Tour";

export default function Home() {
  return (
    <Box className="text-white h-screen w-full justify-center items-center">
      <Showcase />
      <Tour />
    </Box>
  );
}
