import {
  faBackward,
  faBackwardFast,
  faForward,
  faPause,
  faPlay,
  faRepeat,
  faRotateBack,
  faVolumeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";

interface AudioPlayerTypes {
  name: string;
  artist: string;
  src: string;
}

interface AudioPlayerProps {
  allMusic: AudioPlayerTypes[];
}

const AudioPlayer = ({ allMusic }: AudioPlayerProps) => {
  const [musicIdx, setMusicIdx] = useState<number>(0);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [playing, setIsPlaying] = useState<boolean>(false);
  const [audio] = useState(new Audio());
  const [songTitle, setSongTitle] = useState<string>(allMusic[0].name);
  const [songArtist, setSongArtist] = useState<string>(allMusic[0].artist);
  const [songDurationTime, setSongDurationTime] = useState<string>("1:37");
  const [songCurrentTime, setSongCurrentTime] = useState<string>("0:00");

  let name: any = useRef();
  let artist: any = useRef() as any;
  let playPauseBtn: any = useRef() as any;
  let prevBtn: any = useRef() as any;
  let nextBtn: any = useRef() as any;
  let volumeBtn: any = useRef() as any;
  let progressBar: any = useRef() as any;
  let currentTime: any = useRef() as any;
  let durationTime: any = useRef() as any;
  let repeatPlist: any = useRef() as any;

  useEffect(() => {
    name = name.current;
    artist = artist.current;
    playPauseBtn = playPauseBtn.current;
    prevBtn = prevBtn.current;
    nextBtn = nextBtn.current;
    volumeBtn = volumeBtn.current;
    progressBar = progressBar.current;
    currentTime = currentTime.current;
    durationTime = durationTime.current;
    repeatPlist = repeatPlist.current;

    loadMusic();
    updates();
  }, [musicIdx]);

  useEffect(() => {
    if (playing) {
      playMusic();
    } else {
      pauseMusic();
    }
  }, [playing]);

  function loadMusic() {
    setSongTitle(allMusic[musicIdx].name);
    setSongArtist(allMusic[musicIdx].artist);
    audio.src = `./songs/${allMusic[musicIdx].src}.mp3`;
  }

  function handleVolumeClick() {
    setIsMuted(!isMuted);

    if (isMuted) {
      audio.muted = true;
    } else {
      audio.muted = false;
    }
  }

  function handlePlayPauseBtnClick() {
    setIsPlaying((prev) => !prev);
  }

  function playMusic() {
    audio.play();
  }

  function pauseMusic() {
    audio.pause();
  }

  //next music function
  function handleNextBtnClick() {
    setIsPlaying(true);

    if (musicIdx >= allMusic.length - 1) {
      setMusicIdx(0);
    } else {
      setMusicIdx((prev) => prev + 1); //increment of musicIndex by 1
    }
  }

  function handlePrevBtnClick() {
    setIsPlaying(true);

    if (musicIdx <= 0) {
      setMusicIdx(allMusic.length - 1);
    } else {
      setMusicIdx((prev) => prev - 1); //increment of musicIndex by 1
    }
  }

  function handleForward() {
    audio.currentTime += 2;
  }

  function handleBackward() {
    audio.currentTime -= 2;
  }

  function updates() {
    // update progress bar width according to music current time
    audio.addEventListener("timeupdate", (e: any) => {
      const currentTime = e.target.currentTime; //getting playing song currentTime
      const duration = e.target.duration; //getting playing song total duration
      let progressWidth = (currentTime / duration) * 100;
      progressBar.style.width = `${progressWidth}%`;
      progressBar.style.transition = "all .2s ease";

      audio.addEventListener("loadeddata", () => {
        // update song total duration
        let mainAdDuration = audio.duration;
        let totalSec = Math.floor(mainAdDuration % 60);
        let totalMin = Math.floor(mainAdDuration / 60);

        if (totalSec < 10) {
          //if sec is less than 10 then add 0 before it
          totalSec = `0${totalSec}` as any;
        }

        //  durationTime.innerText = `${totalMin}:${totalSec}`;
        setSongDurationTime(`${totalMin}:${totalSec}`);
      });

      // update playing song current time
      let currentMin = Math.floor(currentTime / 60);
      let currentSec = Math.floor(currentTime % 60);

      if (currentSec < 10) {
        //if sec is less than 10 then add 0 before it
        currentSec = `0${currentSec}` as any;
      }
      setSongCurrentTime(`${currentMin}:${currentSec}`);

      if (audio.ended) {
        setIsPlaying(audio.ended);
      }
    });
  }

  return (
    <div className="wrapper text-xl flex flex-col md:flex-row justify-center items-center">
      <div className="flex-row mr-7 hidden md:flex">
        <button
          ref={prevBtn}
          onClick={handleBackward}
          id="prev"
          className="mx-3 cursor-pointer"
        >
          <FontAwesomeIcon icon={faBackward as any} />
        </button>
        <button
          ref={playPauseBtn}
          onClick={handlePlayPauseBtnClick}
          className="play-pause mx-3 rounded-full w-7 h-7 border border-white flex cursor-pointer"
        >
          <FontAwesomeIcon
            icon={playing ? (faPause as any) : (faPlay as any)}
            className="m-auto"
            fontSize={12}
          />
        </button>
        <button
          ref={nextBtn}
          onClick={handleForward}
          id="next"
          className="mx-3 cursor-pointer"
        >
          <FontAwesomeIcon icon={faForward as any} />
        </button>
      </div>
      <div className="song-details uppercase flex flex-col mr-7">
        <span ref={name} className="name text-white font-bold">
          {songTitle}
        </span>
        <span ref={artist} className="artist text-gray-400 -mt-2">
          {songArtist}
        </span>
      </div>
      <div className="progress-area flex flex-row justify-center items-center relative w-3/4 lg:w-1/4 mx-10 my-5 md:my-0">
        <span ref={currentTime} className="current-time mr-2 block md:hidden">
          {songCurrentTime}
        </span>
        <div className="rounded-full w-full h-1 bg-gray-300 relative">
          <div
            ref={progressBar}
            className="progress-bar absolute left-0 rounded-full h-1 bg-primary"
          />
          <div className="w-full opacity-0"></div>
        </div>
        <span ref={durationTime} className="max-duration ml-2 block md:hidden">
          {songDurationTime}
        </span>
      </div>
      <div className="mr-7 hidden md:block">
        <div className="song-timer">
          <span ref={currentTime} className="current-time">
            {songCurrentTime}
          </span>
          <span>/</span>
          <span ref={durationTime} className="max-duration">
            {songDurationTime}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-between w-5/6 md:w-auto md:justify-center ">
        <div
          ref={volumeBtn}
          onClick={handleVolumeClick}
          id="volume"
          className="mx-7 cursor-pointer"
        >
          <FontAwesomeIcon icon={faVolumeHigh as any} />
        </div>
        <div className="flex flex-row md:hidden">
          <button
            ref={prevBtn}
            onClick={handleBackward}
            id="prev"
            className="mx-3 cursor-pointer"
          >
            <FontAwesomeIcon icon={faBackward as any} />
          </button>
          <button
            ref={playPauseBtn}
            onClick={handlePlayPauseBtnClick}
            className="play-pause mx-3 rounded-full w-7 h-7 border border-white flex cursor-pointer"
          >
            <FontAwesomeIcon
              icon={playing ? (faPause as any) : (faPlay as any)}
              className="m-auto"
              fontSize={12}
            />
          </button>
          <button
            ref={nextBtn}
            onClick={handleForward}
            id="next"
            className="mx-3 cursor-pointer"
          >
            <FontAwesomeIcon icon={faForward as any} />
          </button>
        </div>
        <div className="mx-7 cursor-pointer">
          <i ref={repeatPlist} id="repeat-plist" title="Playlist looped">
            <FontAwesomeIcon icon={faRepeat as any} />
          </i>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
