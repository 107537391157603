export const musicData = [
    {
      img: "/images/glory-hour.png",
      title: "Victory Boyd - Glory Hour",
      link: "https://victory.lnk.to/GloryHour",
    },
    {
      img: "/images/just-like-in-heaven.png",
      title: "Victory Boyd - Just Like In Heaven",
      link: "https://victory.lnk.to/JustLikeInHeaven",
    },
    {
      img: "/images/el-shaddai.png",
      title: "Victory Boyd - El Shaddai",
      link: "https://victory.lnk.to/ElShaddai",
    },
];