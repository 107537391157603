import { MotionProps } from 'framer-motion';
import { TransitionPowerOne, TransitionPowerThree } from './Transitions';

export const SlideUpThenSlideDown: MotionProps = {
  initial: {
    bottom: 0,
    opacity: 0,
  },
  animate: {
    bottom: 80,
    opacity: 1,
  },
  exit: {
    bottom: -100,
    opacity: 0,
  },
  transition: { ...TransitionPowerOne },
};

export const SlideLeftSlideRight: MotionProps = {
  initial: {
    x: 20,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
  transition: { ...TransitionPowerThree },
};

export const SlideRightSlideLeft: MotionProps = {
  initial: {
    x: -20,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -20,
    opacity: 0,
  },
  transition: { ...TransitionPowerThree },
};
